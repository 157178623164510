<template>
  <v-form v-if="item" autocomplete="off">
    <v-row>
        <v-col md=7>
          <v-text-field
            id="nameInput"
            name="name"
            label="Nome"
            v-model="item.name"
            hint="nome fantasia da empresa"
          ></v-text-field>

          <v-text-field
            id="emailInput"
            name="email"
            label="Email"
            v-model="item.email"
            hint="email de contato geral"
          ></v-text-field>
          
          <v-text-field
            id="phoneInput"
            name="phone"
            label="Telefone"
            v-model="item.phone"
            return-masked-value
            v-mask="['(##) #####-####', '(##) ####-####']"
            hint="telefone da sede"
          ></v-text-field>



        </v-col>

        <v-col>

        </v-col>

    </v-row>

    <v-row>

        <back-button
          view="small"
        ></back-button>

        <save-button view="big" @save="submit()">
        </save-button>
    </v-row>


  </v-form>
  
</template>

<script>
import SaveButton from '../../components/SaveButton.vue'
import BackButton from '../../components/BackButton.vue'
const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'OfficeForm',
    components: {
      BackButton,
      SaveButton
    },
    props: ['item'],
    methods: {
      submit () {
        return EventBus.$emit('office-submitted')
      }
    }
  }
</script>

<template>
<v-container fluid>
  <v-row>
      <action-bar entity="office"></action-bar>
      <v-col cols=11>

        <v-card>
          <v-card-title>
            <h2 class="title">Escritórios</h2>
          </v-card-title>
        </v-card>

      <div v-if="items">

            <default-table
              v-if="showTable"
              :items="items"
              :headers="headers"
              endpoint="office"
              entity="office"
              :actions="['edit']"
              :statusKeys="[]"
              name="Escritórios"
            >
            </default-table>
      </div>

      <div v-if="item">
          <raw-api-response
            :name="entity"
            :raw="raw_item"
            v-if="showCodeEditor"
          ></raw-api-response>

          <office-form
            :item="item"
            v-if="showForm"
          ></office-form>
      </div>
      
      <div v-if="$store.state.loading">  
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            :src="'/static/illustrations/' + image + '.svg'"
            width="100%"
            icon
          />
      </div>

    </v-col>
  </v-row>
</v-container>
</template>
<script>

const mixins = require('../../addons/mixins.js')
const {
  processOffice,
  officeSchema
} = require('../descriptors.js')

import ActionBar from '../../components/ActionBar'
import OfficeForm from '../components/OfficeForm'
import DefaultTable from '../../components/DefaultTable'
import RawApiResponse from '../../gyra/components/RawApiResponse'


export default {
    name: 'Office',
    components: {
        ActionBar,  
        DefaultTable,
        OfficeForm,
        RawApiResponse,
    },
    mixins: [
      mixins.defaultComputed,
      mixins.defaultEvents,
      mixins.defaultMethods,
      mixins.routeEvents,
      mixins.routeMethods,
    ],
    data() {
      return {
        'image': '',
        'name': 'Escritório',
        'plural_name': 'Escritórios',
        'entity': 'office',
        'redir': '/office',
      }
    },
    mounted() {
      this.runLoad()
    },
    computed: {
      processed: function() {
        return processOffice
      },
      schema: function() {
        return officeSchema
      },
      headers: function() {
        return [
          { text: 'Nome', value: 'name'},
          { text: 'Ações', sortable: false }
        ]
      }
    },
  }
</script>
